
import { ApiGetClientAccountInvoiceBasisDto } from "@/api/generated/Api";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import { ClientAccountInvoiceType } from "@/shared/enums/ClientAccountInvoiceType.enum";
import { isVocationalSchool as getIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import {
  getInvoiceBasisDetails,
  getInvoiceBasisNumbers,
  getInvoiceBasisStatusInfo,
} from "@/shared/helpers/invoiceBasisHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";

interface Row {
  value?: string | number | null | boolean;
  headline: string;
  cols?: string;
  canEdit?: boolean;
  formId?: string;
}

export default defineComponent({
  name: "InvoiceBasisDetails",
  components: {
    BaseSheetHeader,
    BaseIconCard,
  },
  props: {
    invoiceBasisDetails: {
      type: Object as PropType<ApiGetClientAccountInvoiceBasisDto>,
    },
    invoiceBasisType: {
      type: String as PropType<InvoiceBasisType>,
      required: false,
    },
    isTemplate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const course = computed(() => store.state.courses.course);
    const courseId = +route.params.id;
    const invoiceDetailsStatusRows = ref<Row[]>([]);
    const invoiceDetailsPartOne = ref<Row[]>([]);
    const invoiceDetailsPartTwo = ref<Row[]>([]);
    const isVocationalSchool = computed(() => getIsVocationalSchool(store.state.plans.studyplan.mainCourseId));

    const formatClientAccountInvoiceType = (type: string) =>
      type === ClientAccountInvoiceType.AccountingDepartment
        ? "Regnskapsavdeling"
        : type === ClientAccountInvoiceType.Local
        ? "Lokal"
        : "Sentral";

    watch(
      [isVocationalSchool, () => props.invoiceBasisType, () => props.invoiceBasisDetails],
      () => {
        if (props.invoiceBasisDetails) {
          invoiceDetailsPartOne.value = getInvoiceBasisDetails(props.invoiceBasisDetails);
          invoiceDetailsPartTwo.value = getInvoiceBasisNumbers(props.invoiceBasisDetails);

          if (!props.isTemplate) {
            invoiceDetailsStatusRows.value = getInvoiceBasisStatusInfo(props.invoiceBasisDetails);
            course.value.clientAccountInvoiceType &&
              invoiceDetailsStatusRows.value.push({
                value: formatClientAccountInvoiceType(course.value.clientAccountInvoiceType),
                headline: "Fakturagrunnlagtype",
              });
          } else {
            course.value.clientAccountInvoiceType &&
              invoiceDetailsPartOne.value.push({
                value: formatClientAccountInvoiceType(course.value.clientAccountInvoiceType),
                headline: "Fakturagrunnlagtype",
              });
          }
        }
      },
      { immediate: true }
    );

    return {
      course,
      courseId,
      isVocationalSchool,
      invoiceDetailsStatusRows,
      invoiceDetailsPartOne,
      invoiceDetailsPartTwo,
    };
  },
});
