import { ApiGetClientAccountInvoiceBasisDto } from "@/api/generated/Api";
import { InvoiceBasisType } from "../enums/ClientAccount.enum";
import { formatDate, formatDateTime } from "./dateHelpers";
import { formatCurrency } from "./formattingHelpers";
import { capitalize } from "./textHelpers";

export const getInvoiceBasisStatusInfo = (invoiceBasisDetails: ApiGetClientAccountInvoiceBasisDto) => [
  {
    value: capitalize(invoiceBasisDetails.status || "-"),
    headline: "Status",
  },
  {
    value: invoiceBasisDetails.coursePrice ? "Ja" : "Nei",
    headline: "Kursavgift",
  },
  ...(invoiceBasisDetails.updated && invoiceBasisDetails.updatedByName
    ? [
        {
          value: `${invoiceBasisDetails.updatedByName} - ${formatDateTime(invoiceBasisDetails.updated)}`,
          headline: "Oppdatert",
        },
      ]
    : []),
];

export const getInvoiceBasisDetails = (invoiceBasisDetails: ApiGetClientAccountInvoiceBasisDto) => [
  ...(invoiceBasisDetails.invoiceBasisNumber
    ? [
        {
          value: invoiceBasisDetails.invoiceBasisNumber,
          headline: "Grunnlagsnummer",
        },
      ]
    : []),
  {
    value: invoiceBasisDetails.inserted ? formatDate(invoiceBasisDetails.inserted) : "-",
    headline: "Grunnlagsdato",
  },
  {
    value: invoiceBasisDetails.productNo,
    headline: "Produkt",
  },
  {
    value: invoiceBasisDetails.accountingDepartment?.name,
    headline: "Regnskapsavdeling navn",
  },
  {
    value: invoiceBasisDetails.accountingDepartment?.erpOrganizationId || "",
    headline: "Regnskapsavdeling ID",
  },
  {
    value: formatDate(invoiceBasisDetails.courseStartDate),
    headline: "Kursperiode start",
  },
  {
    value: invoiceBasisDetails.courseEndDate ? formatDate(invoiceBasisDetails.courseEndDate) : "-",
    headline: "Kursperiode slutt",
  },
  {
    value: invoiceBasisDetails.courseOrganizer?.name,
    headline: "Arrangør",
  },
  {
    value: `${invoiceBasisDetails.grant?.id} ${invoiceBasisDetails.grant?.name}`,
    headline: "Tilskuddskode",
  },
  {
    value: invoiceBasisDetails.numberOfParticipants,
    headline: "Antall deltakere",
  },
];

export const getInvoiceBasisNumbers = (invoiceBasisDetails: ApiGetClientAccountInvoiceBasisDto) =>
  // Type 2 (Fellestiltak hovedgrunnlag)
  [
    {
      value: formatCurrency(invoiceBasisDetails.totalCostAllocation),
      headline: "Sum kostnadsfordeling",
    },
    {
      value: formatCurrency(invoiceBasisDetails.totalCourseFee),
      headline: "Kursavgift",
    },
    {
      value: formatCurrency(invoiceBasisDetails.invoiceOuFond),
      headline: "Fakturer OU Fond",
    },
    {
      value: formatCurrency(invoiceBasisDetails.shareAofNorge),
      headline: "Andel LO",
    },
    {
      value: formatCurrency(invoiceBasisDetails.costReimbursementFromOuFond),
      headline: "Kostnadsdekning fra OU Fond (Bokføres kto: 7690)",
    },
    {
      value: formatCurrency(invoiceBasisDetails.creditAofDepartment),
      headline: "Godskriv LO-avdeling",
    },
  ];

// Get if a invoice basis is  "Fakturagrunnlag" or "Endringsgrunnlag"
export const getInvoiceBasisType = (
  invoiceBasisNumber: number,
  invoiceBasisList: ApiGetClientAccountInvoiceBasisDto[]
) => {
  // Find the lowest id from the invoice basis list
  const lowestId = Math.min(...invoiceBasisList.map((x) => x.invoiceBasisNumber));

  return lowestId === invoiceBasisNumber ? InvoiceBasisType.InvoiceBasis : InvoiceBasisType.Change;
};
